// About.js
import React from 'react';
import './About.css';

function About() {
  const openSupportPage = () => {
    window.open("https://buy.stripe.com/14k9BPfj16YKdJ628d", '_blank');
  };

  return (
    <div className="container">
      <div class="page-content">
      <h1>About 7juxio</h1>
      <img src="bloombars.jpg" alt="bloombars" class="content-image"/>
      <p>7juxio was created as part an experimental project conducted in elementary schools during the 1980s. The program used ancient cosmology, electronic music, and computer-generated video graphics to develop the student's creative abilities.</p>
      <p>After several years of research, the project had the unintended (?) effect of allowing students to manifest their intentions and will in the astral plane. Following several incidents in the local communities, parents and authorities canceled the program and banned the 7juxio software.</p>
      <p>Recently, the original source code was recovered in a storage unit outside of DC, having previously been liberated by a participant before the program was shut down and the equipment destroyed. A new version the software is currently in development for future release.</p>
      <p>This web app was built in React using ChatGPT. We look forward to our continued partnership with AI tools to deliver this project.</p>
      <button className="donate-button" onClick={openSupportPage}>
        Click here to support 7juxio development
      </button>
      </div>
      <div class="page-content-side">
      
      </div>
      
    </div>
  );
}

export default About;
