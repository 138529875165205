// DmtvideoButtons.js
const videoButtons = [
  { label: 'Keep Grooving', file: 'Keep Grooving.mp4' },
  { label: 'Eeds Line', file: 'Eeds Line.mp4' },
  { label: 'Kukulkan', file: 'Kukulkan.mp4' },
  { label: 'Open Source', file: 'Open Source.mp4' },
  { label: 'Capoeira', file: 'Capoeira.mp4' }
];
console.log(videoButtons);
export default videoButtons;
