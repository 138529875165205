import React from 'react';
import './VideoPlayer.css'; // Create a new CSS file for VideoPlayer component styles

const VideoPlayer = ({ video }) => {
  console.log('VideoPlayer received new video:', video);

  // Extract title from the video file name
  const title = video.replace(/\.[^/.]+$/, '');

  // Determine the file extension
  const fileExtension = video.split('.').pop().toLowerCase();

  // Define supported video and audio file extensions
  const supportedVideoExtensions = ['mp4'];
  const supportedAudioExtensions = ['mp3'];

  // Check if it's a video or audio file
  const isVideo = supportedVideoExtensions.includes(fileExtension);
  const isAudio = supportedAudioExtensions.includes(fileExtension);

  return (
    <div className="video-container">
      <h2>{title}</h2>

      {isVideo && (
        <video className="video-player" controls width="90%" autoPlay loop>
          <source src={process.env.PUBLIC_URL + '/videos/' + video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {isAudio && (
        <audio className="audio-player" controls autoPlay loop>
          <source src={process.env.PUBLIC_URL + '/videos/mp3s/' + video} type="audio/mp3" />
          
          Your browser does not support the audio tag.
        </audio>
      )}
    </div>
  );
};

export default VideoPlayer;
