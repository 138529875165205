import React, { useState, useEffect } from 'react';
import './App.css';
import HeaderMenu from './HeaderMenu';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home.js';
import AboutPage from './About.js';
import ContactPage from './Contact.js';
import AlbumTemplate from './AlbumTemplate.js';
import DMTPage from './Dmt.js';
import MindWaves from './Mindwaves.js';
import Diskone from './Diskone.js';
import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-BQ66QC852V'); // Replace with your Google Analytics tracking ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <Router>
      <div className="app">
        <div className="header">
        <a href="/"><img src="7juxio.png" alt="7juxio label logo" className="header-logo" /></a>
          <div className="menu-icon" onClick={toggleMenu}>
            ☰
          </div>
        </div>
        {menuVisible && (
          <div className="overlay" onClick={toggleMenu}>
            {/* The HeaderMenu component goes inside the overlay */}
            <HeaderMenu />
          </div>
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/album-template" element={<AlbumTemplate />} />
          <Route path="/dmt" element={<DMTPage />} />
          <Route path="/mindwaves" element={<MindWaves />} />
          <Route path="/diskone" element={<Diskone />} />
          {/* Add more routes as needed */}
        </Routes>
        {/* <div className="footer">
          <span className="footer-statement">&copy; {new Date().getFullYear()} 7jux.io</span>
        </div> */}
      </div>
    </Router>
  );
}

export default App;
