import React, { useState } from 'react';

function AlbumRow() {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal visibility
  const handleImageClick = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="image-row">
      <img src="disk2.png" alt="7juxio Disk 2: Tunal" className="album-image" onClick={handleImageClick} />
      <a href="https://mindwaves.7jux.io"><img src="mindwaves.jpg" alt="7juxio Mindwaves 1" className="album-image" /></a>
      <a href="https://dmt.7jux.io"><img src="dmt-cover.jpg" alt="7juxio Dream Manifestation Toolkit" className="album-image" /></a>
      <a href="/diskone"><img src="diskone.png" alt="7juxio Disk 1" className="album-image" /></a>
      
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <p>Coming Soon</p>
          </div>
        </div>
      )}
    </div>
  );
}
export default AlbumRow;