// Contact.js
import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <p>No 7juxio connection available</p>
    </div>
  );
}

export default Contact;
