// HeaderMenu.js
import React from 'react';
import './HeaderMenu.css'; // Import the CSS file for styling
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';

function HeaderMenu() {
  return (
    <div className="header-menu">
      <a href="/">Home</a>
      <a href="/about">About</a>
      <a href="/contact">Contact</a>
      {/* Add more menu items as needed */}
    </div>
  );
}

export default HeaderMenu;
