// Home.js
import React, { useState, useEffect } from 'react';  // Add this line
import AlbumRow from './AlbumRow'; // Import the new component
import ReactGA from 'react-ga';
import './Home.css';

function Home() {
  useEffect(() => {
    ReactGA.set({ title: 'Home Page' });
  }, []);
    return (
      <div className="main">
                 
        <h1>fresh hypermedia</h1>
        <AlbumRow /> {/* Include the AlbumRow component */}
        
      </div>
    );
  }

export default Home;
