import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import videoButtons from './DmtvideoButtons'; // Import the videoButtons array
import './Albums.css';
import './Dmt.css';

const AppContent = () => {
  const location = useLocation();
  const [userName, setUserName] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    console.log('Effect triggered. Selected Video:', selectedVideo);
    // Existing code for getting and setting user name
    const storedName = localStorage.getItem('userName');
    if (!storedName) {
      const name = prompt('Please enter your name:');
      if (name) {
        setUserName(name);
        localStorage.setItem('userName', name);
      }
    } else {
      setUserName(storedName);
    }

    window.scrollTo(0, 0);
  }, [location.pathname, location.search, selectedVideo]);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    console.log('Button clicked. Selected Video:', video);
  };

  return (
    <div className="container">
      <div className="nav-column2">
        {userName && <p>Welcome, {userName}!</p>}
        <img src="dmt-cover.jpg" alt="DMT Cover" className="album-cover2" />
        <h1 className="album-title">Dream Manifestation Toolkit</h1>

        <p className="album-blurb">A hypermedia mixtape of remixes and visuals by 7juxio</p>
        <div className="contents-box"><p>Contents</p>
        {videoButtons.map((video, index) => (
  <button key={index} onClick={() => handleVideoSelect(video.file)} className="album-button">
    {video.label}
  </button>
))}
</div>
      </div>
      <div className="video-column2"> 
        {selectedVideo && <VideoPlayer key={selectedVideo} video={selectedVideo} />}
      </div>
    </div>
  );
};

export default AppContent;

