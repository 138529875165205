// AlbumTemplate.js
import React from 'react';

function AlbumTemplate() {
  return (
    <div>
      <h1>Album Title</h1>
      <p>This is a placeholder album page</p>
    </div>
  );
}

export default AlbumTemplate;
