// DmtvideoButtons.js
const videoButtons = [
  { label: 'Boot Sequence', file: 'Boot Sequence.mp4' },
  { label: 'Grind', file: 'Grind.mp3' },
  { label: 'Just Like', file: 'Just Like.mp4' },
  { label: '111', file: '111 ft Kokayi.mp4' },
  { label: "Don't Break the Bubble", file: "Don't Break the Bubble.mp4" },
  { label: "Ghostbird", file: "Ghostbird ft Drew Kid.mp3" },
  { label: "Strikes", file: "Strikes.mp3" },
  ];
console.log(videoButtons);
export default videoButtons;


